import { createGlobalStyle } from "styled-components"
import * as fonts from "../fonts"

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: normal;
  src: url('${fonts.UbuntuRegularTTF}') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: bold;
  src: url('${fonts.UbuntuBoldTTF}') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: normal;
  src: url('${fonts.UbuntuItalicTTF}') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: bold;
  src: url('${fonts.UbuntuBoldItalicTTF}') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: lighter;
  src: url('${fonts.UbuntuLightTTF}') format('truetype');
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}
html, #___gatsby, #gatsby-focus-wrapper {
  height: 100%;
}`

export default GlobalStyle
