import React from "react"
import styled from "styled-components"
import PageTitle from "./pageTitle"

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Welcome = () => (
  <WelcomeWrapper>
    <PageTitle value="New site is currently under construction!" />
  </WelcomeWrapper>
)

export default Welcome
