import React from "react"
import styled from "styled-components"
import { theme } from "./theme"

const FooterWrapper = styled.div`
  margin-top: 20px;
  color: ${theme.black};
`

const FooterElement = styled.i`
  display: flex;
  font-family: "Ubuntu";
  font-size: 14px;
  justify-content: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
  a {
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px solid ${theme.blue};
    color: ${theme.blue};
  }
`
const Footer = () => (
  <FooterWrapper>
    <FooterElement></FooterElement>
  </FooterWrapper>
)

export default Footer
