import React from "react"
import SEO from "../components/seo"
import Welcome from "../components/welcome"
import Layout from "../components/layout"

const IndexPage = () => (
  <React.Fragment>
    <SEO title="Welcome" />
    <Layout>
      <Welcome />
    </Layout>
  </React.Fragment>
)

export default IndexPage
