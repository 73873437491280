import React from "react"
import styled from "styled-components"
import GlobalStyle from "../styles/globalStyle"
import Footer from "./footer"
import Header from "./header"

const LayoutElement = styled.div`
  min-width: 333px;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const BodyElement = styled.div`
  flex-grow: 1;
`

const Layout = props => (
  <LayoutElement>
    <Header />
    <BodyElement>{props.children}</BodyElement>
    <Footer />
    <GlobalStyle whiteColor />
  </LayoutElement>
)

export default Layout
