import { default as React } from "react"
import styled from "styled-components"
import { theme } from "./theme"

const HeaderElement = styled.span`
  display: flex;
  font-family: "Ubuntu";
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: white;

  .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    .nav {
      height: 100%;
      margin-left: auto;
    }
  }

  img {
    margin: 11px 15px;
    display: flex;
    border-radius: 50%;
    border: 1px ${theme.black} solid;
    box-shadow: 0px 3px 7px silver;
    height: 45px;
    width: 45px;
  }
`
const Header = () => <HeaderElement></HeaderElement>

export default Header
